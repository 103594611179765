<template>
  <button
      class="button fs_regular"
      :class="[type, { small: small }]"
      @click="handleClick"
  >
    {{ title }}
  </button>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  type: {
    type: String,
    default: 'normal',
    validator(value) {
      return ['normal', 'white', 'primary', 'okCancel'].includes(value)
    }
  },
  small: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['handleClick'])

const handleClick = () => {
  emit('handleClick')
}
</script>

<style scoped lang="scss">
.button{
  font-size: 18px;
  line-height: 56px;
  text-align: center;
  color: #000000;
  &.normal{
    width: 100%;
    background-color:#CCC;
    border: 0px solid #fff;
  }
  &.white{
    width: 100%;
    background-color:#fff;
    border: 0px solid #fff;
  }
  &.primary{
    width: 100%;
    background-color:#002C5F;
    border: 0px solid #002C5F;
    color:#fff;
  }
  &:disabled,
  &[disabled]{
    background-color: #cccccc;
    color: #fff;
  }
  &.popup_normal{
    width: 100%;
    background-color:#ffffff;
    border: 0px solid #002C5F;
    border-top:1px solid RGBA(0,0,0,0.12);
    color:#000;
  }
  &.small {
    line-height: 48px;
  }
}

@media (min-width:768px) {
  .button {
    &.small {
      line-height: 40px;
    }
  }
}
</style>
